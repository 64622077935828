// Typography
$primary-font: "Noto Sans TC", sans-serif;
$secondary-font: "Overpass", sans-serif;

// Colours
$blue: #0049c6;
$blue-dark: #003ba0;
$blue-darkest: #001344;
$blue-light: #1b97ba;
$blue-ice: #f0f8fd;
$blue-highlight: #62bcf8;
$blue-sky: #0b91ea;
$light-blue: #62bcf8;

$green: #18c1ad;
$green-dark: #0eb0a9;
$green-light: #defcf8;

$red: #c21818;
$red-light: #fff0f0;
$red-dark: #ac0d34;
$orange: #f58020;
$gold: #ffb22d;

$white: #fff;
$black: #000;

$grey-lightish: #bad2de;
$grey: #92a4ad;
$grey-mid: #646f81;
$grey-dark: #262e3f;
$grey-dark-blue: #262e3f;

$grey-bg: #f6f8f9;
$grey-ice: #f0f8fd;
$grey-light: #eff4f7;
$grey-lines: #e1eaf0;

$small-shadow: 0 0.125rem 0.25rem 0 rgba(0, 39, 66, 0.02);
$blue-gradient: linear-gradient(0deg, #0055b0 0%, $blue-dark 100%);
$blue-gradient-horizontal: linear-gradient(90deg, #0055b0 0%, $blue-dark 100%);

// Breakpoints
$tablet-breakpoint: 48rem !default;
$laptop-breakpoint: 64rem !default;
$desktop-breakpoint: 83.375rem !default;
