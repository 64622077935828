@import "~assets/styles/_variables.scss";

@mixin shared-styles() {
    width: 1.125rem;
    height: auto;
    cursor: pointer;
}

.eyeIcon {
    @include shared-styles();
    fill: $grey-dark;
}

.eyeIconClosed {
    @include shared-styles();
    fill: $grey;
    transform: translateY(0.125rem);
}
