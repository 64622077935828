img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: initial;
    vertical-align: initial;
}

.body {
    width: 100%;
    background-color: #fff;
    margin: 40px;
    border: 2px solid #000000;
    width: 880px;
}

.titleContainer {
    width: 100%;
    background-color: #c0c0c0;
    display: flex;
    border-bottom: 2px solid black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleWrapper {
    width: 100%;
    display: flex;
    /* align-items: center;
    justify-content: center; */
    padding: 5px;
    position: relative;
}

.titleSubtitleWrapper {
    text-align: center;
    width: calc(100% - 150px);
}

.titleWrapperH1 {
    font-size: 1.4rem !important;
    text-justify: none;
}

.subtitle {
    font-size: 0.8rem !important;
    text-justify: none;
}

.rightSubContainer {
    position: absolute;
    right: 0;
    top: 0;
    border-left: 2px solid;
    height: 100%;
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleWrapperSpan {
    text-align: center;
    font-size: 0.7rem !important;
    font-weight: 500;
    text-justify: none;
}

.row {
    display: flex;
    border-bottom: 2px solid black;
}

.cell {
    padding: 2px 2px 10px 2px;
    position: relative;
}

.cellFlex {
    display: flex;
    position: relative;
}

.cellSmallPadding {
    padding: 2px;
    position: relative;
}

.noBorderBottom {
    border-bottom: none !important;
}

.borderBottom {
    border-bottom: 2px solid black;
}

.borderRight {
    border-right: 2px solid black;
}

.borderLeft {
    border-left: 2px solid black;
}

.marginRight {
    margin-right: 10px;
}

.noPadding {
    padding: 0 !important;
}

.paddingRight {
    padding-right: 30px;
}

.cellFullFlex {
    flex: 1;
    position: relative;
}

.nameContainer {
    display: flex;
    margin-bottom: 0.4rem;
}

.nameContainerSpan {
    color: red;
    font-weight: bold;
    font-size: 1.1rem !important;

    text-justify: none;
}

.inputName {
    flex: 1;
    display: flex;
    justify-content: center;
    border-bottom: 2px solid black;
}

.regText {
    font-size: 0.75rem !important;
    font-weight: 700;
    text-justify: none;
}

.projectNumber {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: center;
    text-justify: none;
}

.projectNumberSpan {
    font-size: 0.65rem !important;
    font-weight: bold;
    text-justify: none;
}

.projectNumberP {
    color: #1e449a;
    font-size: 1.1rem !important;

    font-weight: bold;
    text-justify: none;
}

.smallTitle {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid black;
    background-color: #c0c0c0;
    padding: 2px;
    height: fit-content;
    text-justify: none;
    position: relative;
}

.smallTitle h2 {
    font-size: 1rem !important;
    position: relative;
    text-justify: none;
}

.titleSupText {
    font-size: 0.7rem !important;
    position: absolute;
    right: 15px;
    bottom: 3px;
    text-justify: none;
}

.cellTextContainer {
    display: flex;
    gap: 10px;
    height: 30px;
}

.cellTextContainer > span {
    font-size: 0.7rem;
    min-width: 100px;
    text-justify: none;
}

.cellTextContainerSmall,
.cellTextContainerSmall span {
    font-size: 0.6rem !important;
    min-width: 50px;
    text-justify: none;
}

.cellTextContainer p {
    color: #1e449a;
    font-size: 0.7rem !important;

    font-weight: bold;
    vertical-align: middle;
    display: flex;
    align-items: center;
    text-justify: none;
}

.cellFlexCenter {
    display: flex;
    align-items: center;
}

.selectionBoxContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.selectionBoxWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    flex: 1;
    position: relative;
}

.boxContainer {
    width: 17px;
    height: 17px;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stampBox {
    position: relative;
}

.boxX {
    min-width: 0 !important;
    font-weight: 900;
}

.cellTextContainerReg > p {
    font-size: 0.8rem !important;

    min-width: 100px;
    font-weight: 400;
    color: #000000;
    text-justify: none;
}

.columnA {
    border-right: 2px solid black;
    flex: 1;
}

.columnB {
    display: flex;
    flex-direction: column;
}

.designerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin: 20px;
    padding: 35px;
    font-size: 1.3rem !important;

    font-weight: bold;
    text-align: center;
    color: #fff;
    text-justify: none;
}

@media print {
    @page {
        size: 250mm 405mm;
    }
}

.bodyCoverage {
    width: 100%;
    background-color: #fff;
    margin: 5px;
    width: 950px;
    height: 1560px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 50px;
    box-sizing: border-box;
    margin-bottom: 20px;
}

.fullWidth {
    width: 60%;
}

.logoTitleContainer {
    align-self: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    margin-top: auto;
    padding: 0 50px;
}

.logoTitleP {
    text-align: center;
    font-size: 28px;
}

.designHeatGainLossContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 50px;
    margin-top: 40px;
    border-top: 2px solid black;
    border-bottom: 2px solid black;
}

.designHeatGainLossWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.designHeatGainLossSpan {
    font-size: 24px;
    text-align: center;
    font-weight: bold;
    border-bottom: 2px solid #afafaf;
    width: 100%;
    padding: 10px 0;
}

.designHeatGainLossP {
    font-size: 36px;
    text-align: center;
    font-weight: bold;
    margin: 10px 0;
}

.footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    margin-bottom: 100px;
    margin-left: 50px;
}

.footerSpan {
    font-size: 20px;
}

.footerLastSpan {
    margin-top: 20px;
}

.textWithBoxContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 0.6rem auto;
    position: relative;
}

.textWithBox {
    font-size: 1rem !important;
    font-weight: 600;
    color: #000000;
    text-justify: none;
}

.boxWithTextWrapper {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
}

.rectangle {
    width: 150px;
    height: 30px;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

.textRowWithNumber {
    display: flex;
    gap: 10px;
    padding: 0 20px;
    font-size: 0.75rem !important;
}

.bottomBorder {
    border-bottom: 2px solid black;
}

.boxesContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-right: auto;
}

.heatText {
    font-size: 1rem !important;
    font-weight: 700;
    text-justify: none;
}

.smallNumberFields {
    font-size: 0.5rem !important;
    position: absolute;
    bottom: 2px;
    right: 2px;
    min-width: unset !important;
}
