*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    border: 0;
    box-sizing: border-box;
}

html {
    height: 100vh;
    scroll-behavior: smooth;
}

body {
    height: 100vh;
    overflow: hidden;
    background-color: $grey-bg;

    > div:first-of-type {
        height: 100%;
    }
}

img {
    height: auto;
    max-width: 100%;
    display: block;
}

.invisible {
    @include invisible;
}

.normalize-button {
    background: none;
    outline: none;
}

@media print {
    body {
        height: initial;
        overflow-y: initial;
        overflow-x: initial;
    }
}
