@import "~assets/styles/_variables.scss";

.upgradesTableWrapper {
    overflow-x: scroll;
    overflow-y: hidden;
    position: relative;
    transform: rotateX(180deg);
    padding-top: 3.5rem;

    &.scrolled {
        td:first-of-type,
        th:first-of-type {
            &::after {
                content: "";
                width: 5px;
                height: 100%;
                position: absolute;
                top: 0;
                right: -5px;
                background: linear-gradient(90deg, rgba($grey, 0.3) 0%, rgba($grey, 0) 100%);
            }
        }
    }
}

// TODO: clean this all up a bit... name cell classes based on colour, padding, border, etc?
.upgradesTable {
    width: auto;
    table-layout: fixed;
    transform: rotateX(180deg);

    .hiddenRow {
        display: none;
    }

    // Headers
    .upgradesTableHead {
        border-bottom: 0.125rem solid $grey-lines;
    }
    .headCell {
        background-color: $white;
        font-size: 0.875rem;
        border-right: 0.125rem solid $grey-lines;

        :global {
            .label {
                height: auto;
            }
        }

        &:last-child {
            border-right: none;
        }

        &.upgradeCell {
            border-width: 1px;

            > span {
                padding: 0;
            }
        }
    }
    .dividerCell {
        background-color: $grey-bg;
        padding: 0.125rem 0.875rem;
        border-right: 0.125rem solid $grey-bg;

        &.freezeCell {
            background-color: $grey-bg;
            // z-index:5 !important;
        }
    }
    .simulationHeaderCell {
        background-color: $blue;
        color: $white;
        padding: 0.5rem 0.875rem;
        z-index: 5 !important;
    }
    .regularCell {
        border-right: 0.125rem solid $grey-lines;
        padding: 0.75rem 0.875rem;

        &.freezeCell {
            background-color: $white;
        }
        &.cellWithCost {
            border-right-width: 1px;
        }

        &:last-child {
            border-right: none;
        }

        &.fadeCell {
            opacity: 0.5;
        }

        &.upgradeCell {
            transition: background-color 0.3s ease-in-out;
            border-left: 1px solid $grey-lines;
            border-width: 1px;
            padding: 0;

            > span {
               display: flex;
               flex-direction: column; 
            }

            .edit {
                opacity: 0;
                margin-left: 0.75rem;
                cursor: pointer;
                line-height: 0;
                transform: translateX(-0.875rem);
                transition: opacity 0.3s ease-in-out;

                svg {
                    height: 1rem;
                    width: 1rem;

                    > g {
                        fill: $blue;
                    }
                }
            }
            .upgradeCellText {
                display: flex;
                align-items: center;
                height: 100%;
                border-top: 1px solid $grey-lines;

                &:first-of-type {
                    border-top: 0;
                    padding-top: 0;
                    margin-top: 0;
                }
            }
            .upgradeCellTextLabel {
                margin-right: auto;
                padding: 0.75rem 0.875rem;

                &.rightAlign {
                    margin-left: auto;
                    margin-right: 0;
                }
            }

            &:hover {
                background-color: $grey-ice;

                .edit {
                    opacity: 1;
                }
            }
        }
        &.noPadding {
            padding: 0;
        }
    }
    .baseCell {
        background-color: $grey-bg;
    }

    .freezeCell {
        position: sticky;
        left: 0;
        z-index: 4;
    }

    .flexCell {
        .rowToggle.hide {
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }

        > span {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        strong {
            margin-right: 0.5rem;
        }

        &:hover .rowToggle {
            opacity: 1;
        }
    }
    .upgradeCellCostText {
        padding: 0.75rem 0.5rem 0.75rem 0.75rem;
        width: 7rem;
        min-width: 7rem;
        display: flex;
        justify-content: center;
        align-items: center;
        align-self: stretch;
        border-left: 1px solid $grey-lines;

        > span {
            margin-right: auto;
        }
    }
}

.runSimulationTableButton {
    color: $white;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    margin-left: auto;
    position: sticky;
    right: 0.875rem;
    z-index: 2 !important;
    cursor: pointer;

    &:disabled {
        opacity: 0.5;
        pointer-events: none;
    }
}

.upgradeHead {
    display: flex;
    height: 100%;
}

.upgradeHeadLabel {
    align-self: center;
    padding: 0.875rem 1.375rem 0.875rem 0.875rem;
}

.upgradeHeadCost {
    width: 7rem;
    min-width: 7rem;
    display: flex;
    align-items: center;
    border-left: 1px solid $grey-lines;
    padding: 0.875rem;
}

.upgradeHeadMenu {
    height: 100%;
}

.upgradeHeadMenuWrapper {
    align-self: center;
    position: relative;
    transform: translateX(-0.875rem);
    z-index: 2;
}

.upgradeHeadDropdown {
    position: absolute;
    top: calc(100% + 0.875rem);
    width: 17rem;
    left: -15rem;
    z-index: 3;
}

.upgradeHeadBaseDropdown {
    position: absolute;
    top: calc(100% + 0.875rem);
    width: 17rem;
    left: 0rem;
    z-index: 10;
}

.noDropdownLabel {
    display: none;
}

.dropdownLabel {
    display: flex;
    align-items: center;

    svg {
        margin-right: 0.5rem;

        // causes HouseSelect icon to have a much slower transition (>1s) 
        // g {
        //     transition: fill 0.3s ease-in-out !important;
        // }
    }

    &:hover svg g {
        fill: $blue;
    }
}

.deleteLabel {
    color: $red !important;

    svg g {
        fill: $red !important;
    }

    &:hover {
        background-color: $red-light !important;
    }
}

.upgradeHeadSortWrapper {
    margin-left: auto;
    margin-right: 0.875rem;
    display: flex;
    align-items: center;

    button {
        cursor: pointer;

        &:first-child {
            transform: rotate(90deg);
        }
        &:last-child {
            transform: rotate(-90deg);
        }
    }

    svg {
        width: 0.75rem;
        height: auto;
        fill: $blue-dark;
    }
}

.graphRow {
    display: flex;
    align-items: center;
    padding: 0.25rem 0.875rem;
    font-size: 0.75rem;
    line-height: 1.125rem;
}

.graphHeading {
    border-top: 1px solid $grey-lines;

    &:first-child {
        border-top: none;
    }

    > span {
        display: block;
        height: 1rem;
        width: 1rem;
        border-radius: 1px;
        margin-right: 0.5rem;
    }
}

.graphBar {
    max-width: 12rem;

    .graphBarBar {
        height: 1rem;
        background-color: $white;
        width: 0.25rem;
        min-width: 0.125rem;
        margin-right: 0.5rem;
        flex-shrink: 0;
        // flex-grow: 1;
        max-width: 18.125rem;
    }
}

.graphLabel {
    white-space: nowrap;
    font-size: 0.625rem;
}

.noResultsCell {
    height: 13.75rem !important;
    position: static !important;

    p {
        margin-bottom: 1.25rem;
    }
}

.outOfSyncCell {
    position: relative;
    opacity: 1 !important;
}

.box {
    background-color: $blue-ice;
    border: 1px solid $grey-lines;
    border-radius: 0.25rem;
    text-align: center;
}

// TODO: Share styles?
.outOfSyncBox {
    composes: box;
    position: absolute;
    z-index: 2;
    top: 9.5rem;
    max-width: 17rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 1rem);
    padding: 1.5rem;

    p {
        max-width: 12.75rem;
        color: $grey-dark;
        margin-bottom: 1.25rem;
    }
}

.noResultsBox {
    composes: box;
    position: absolute;
    z-index: 2;
    left: 0.5rem;
    bottom: 0.875rem;
    width: calc(100% - 1.25rem);
    max-width: 73.75rem;
    padding: 3.5rem 1.5rem;
    min-height: 12.125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.simulateButtonRunning {
    svg {
        animation-name: ckw;
        animation-duration: 3s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }
}

.selectBreakdown {
    cursor: pointer;
    position: relative;
    // word-wrap: break-word;
    white-space: pre-line;
}

.resultsText {
    display: flex;
    // justify-content: space-around;

    * {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
}

.dropDown {
    width: 25rem;
    height: fit-content;
    background-color: $blue;
    left: 0;
    bottom: 0;
    right: 0;
    top: 1.75rem;
    margin-left: -0.85rem;
    max-height: none;
    position: absolute;
    border: 1px solid rgba(0, 59, 160, 1) !important;
    border-radius: 4px;
    padding: 0px !important;

    // &.dropDownExtraTop {
    //     top: 3rem;
    // }

    // &.dropDownEmissions {
    //     top: 4.3rem;
    // }

    .breakdown {
        color: white;
        height: 2rem;
        // width: 100%;
        padding: 0px;
        display: flex;
        border-radius: 4px;
        margin: 0.3rem;

        p {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        &.selected {
            background: rgba(255, 255, 255, 0.07);
            .checkmarkDiv {
                img {
                    display: block;
                }
            }
        }

        &:hover {
            background: rgba(255, 255, 255, 0.07);
        }
    }
    .checkmarkDiv {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;

        img {
            display: none;
            width: 0.7rem;
            height: 0.7rem;
        }
    }

    ul {
        padding: 0px !important;
    }

    li {
        padding: 0px !important;
        span {
            display: flex;
            align-items: center;
        }
    }

    li:hover {
        background-color: $blue !important;
    }
}

@keyframes ckw {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
