@import "~assets/styles/_variables.scss";

.section {
    padding: 1.875rem 1.875rem 2.5rem;

    p {
        line-height: 1.5rem;
    }
}

.accordion {
    margin-bottom: 0.625rem;
    
    .testDataBody {
        padding: 0rem !important;
    }
}

.accordionIconHeading {
    display: flex;
    gap: 0.5rem;
}

@media print {
    .section {
        padding: 1rem;

        p {
            font-size: 0.75rem;
        }
    }
}

.bottomAlign {
    align-items:flex-end;
}
