@import "~assets/styles/_variables.scss";

.dialog {
    text-align: center;
    padding: 2rem 0;

    h2 {
        margin-bottom: 1.5rem;
    }

    h3 {
        margin-bottom: 1.5rem;
        font-weight: 400;

        strong {
            font-weight: 700;
        }
    }

    img {
        margin: -1rem auto 0.5rem;
        width: 1.875rem;
        height: 1.875rem;
        border-radius: 0.25rem;
    }

    .footer {
        display: grid;
        column-gap: 1rem;
        justify-content: center;
        grid-template-columns: 10rem 10rem;
        margin-top: 1.5rem;

        button {
            width: 100%;
        }
    }
}
