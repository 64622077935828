.App-header {
    text-align: center;
    padding: 3rem 0;

    button {
        margin: 2rem 0.5rem 0;
        min-width: 10rem;
    }
}

.App-Body {
    h2 {
        text-align: center;
    }
}