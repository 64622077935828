@import "~assets/styles/_variables.scss";

// Code drawers:

.editCode {
    position: relative;
    margin-left: auto;
    border-radius: 0.25rem;

    svg {
        margin-right: 0 !important;
        width: 0.875rem !important;
        height: 0.875rem !important;

        > g {
            fill: $grey !important;
        }
    }

    &:hover {
        svg > g {
            fill: $blue !important;
        }
    }

    .fieldTooltip {
        padding: 0.25rem 0.875rem !important;
        margin-top: 0px !important;
        left: -60px !important;
        top: -13px !important;
        position: absolute !important;
        width: min-content !important;
    }
}

img.diagram {
    margin: 0 auto 2.5rem;
    max-width: 34rem;
}

.buttons {
    display: grid;
    grid-template-columns: 12.5rem 12.5rem;
    column-gap: 1.25rem;
    align-items: center;
    justify-content: center;
    margin-top: 3.5rem;
}

.type {
    border-bottom: 1px solid $grey-lines;
    padding-bottom: 2rem;
    margin-bottom: 1.5rem !important;
}

.standardCodeWrapper {
    h3 {
        margin-bottom: 1.25rem;
    }
}
