@import "~assets/styles/_variables.scss";

.heatmap {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;

    :global {
        .plotly .modebar {
            transform: translateY(4.25rem);
        }
    }
}

.button {
    margin-left: 2rem;
    margin-top: 3rem;
    border-radius: 0.375rem;
    outline: none;
    background-color: $grey-lightish;
    color: $white;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.125rem;
    padding: 0 1.25rem;
    height: 2.5rem;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out, border-color 0.3s ease-in-out;

    svg {
        max-width: 1.125rem;
        max-height: 1.125rem;
    }

    > span {
        transform: translateY(-0.0625rem);
        display: block;
    }

    &:hover {
        background-color: $grey;
    }

    &:disabled {
        background-color: rgba($grey, 0.4);
    }

    &.large {
        height: 2.875rem;
    }

    &.small {
        padding: 0 0.875rem;
        height: 2.25rem;
    }

    &.smallPadding {
        padding: 0 0.875rem;
    }

    &.hasIcon {
        span {
            display: flex;
            align-items: center;

            svg {
                margin-right: 0.625rem;
                opacity: 0.8;
                transform: translateY(0.0625rem);
            }
        }
    }

    &.hollow {
        background-color: transparent;
        color: $blue;
        border: 1px solid $grey-lines;

        &:hover {
            background-color: $grey-light;
            border-color: $blue;
        }
    }

    &.red {
        background-color: $red;

        &:hover {
            background-color: $red-dark;
        }
    }

    &.white {
        background-color: $white;
        color: $grey-dark-blue;

        svg,
        svg > g {
            fill: $blue;
        }

        &:hover {
            background-color: $grey-light;
        }
    }

    &.emptyBg {
        background-color: transparent;
        border: 1px solid transparent;
        color: $blue;

        &:hover {
            background-color: $grey-light;
            border-color: $blue;
        }
    }

    &.slate {
        background-color: $grey-mid;

        &:hover {
            background-color: $grey-dark-blue;
        }
    }
}

.placeholder {
    width: 60vw;
    display: flex;
    justify-content: center;
    align-items: center;
}

.placeholder > p {
    position: absolute;
    background-color: white;
    padding: 1.5rem;
    border-radius: 0.5rem;
    // box-shadow: 0 0 5px 5px white;
    // box-shadow: 10rem 10rem black ;
}