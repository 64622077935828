@import "~assets/styles/_variables.scss";

.costInput {
    font-size: 0.75rem;
    line-height: 1.25rem;
    width: 5rem;
    text-align: right;
    padding: 0 0.25rem;
    border-radius: 0.25rem;
    transition: background-color 0.3s ease-in-out;

    &:focus {
        outline-color: $blue-highlight;
    }
}
