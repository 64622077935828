@import "~assets/styles/_variables.scss";

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.MuiAlert-filledInfo {
    background-color: $blue !important;
    color: $white !important;
}

.MuiAlert-filledWarning {
    background-color: $gold !important;
    color: $white !important;
}

.MuiAlert-filledSuccess {
    background-color: $green-dark !important;
    color: $white !important;
}
