@import "~assets/styles/_variables.scss";

.create {
    margin: 0 auto;
    max-width: 50rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 10.625rem); // main wrapper padding

    .createHeading {
        text-align: center;
    }

    .folderText {
        text-decoration: underline solid transparent;
        transition: all 0.2s ease-in-out;

        &:hover {
            text-decoration: underline solid Currentcolor;
        }
    }

    .card {
        padding: 0;
        min-width: 50rem;
        padding: 1.875rem 1.875rem 3rem;
        // min-height: 36rem;
        display: flex;
        flex-direction: column;
        background-color: $white;
        border-radius: 0.625rem;
        box-shadow: 0 0.125rem 0.25rem 0 rgba(0, 39, 66, 0.06);
    }

    .createForm {
        padding: 1.875rem 1.875rem 3rem;
        min-height: 36rem;
        display: flex;
        flex-direction: column;

        .lastRow {
            margin-top: 1rem;
            margin-bottom: auto !important;
        }

        > button {
            display: block;
            margin: 2.5rem auto 0;
            min-width: 10rem;
        }

        .sectionHeader {
            margin-bottom: 1rem;
        }
    }

    .divider {
        width: 100%;
        height: 1px;
        background: $grey-lightish;
        opacity: 0.6;
        margin-bottom: 1rem;
        // transition: all 0.1s ease-in-out;
    }
}

.close {
    top: 1rem;
    right: 1rem;
    height: 3rem;
    width: 3rem;

    > div {
        height: 1.375rem;
        width: 1.375rem;
        position: relative;
        transform: translateY(1px);

        &::after,
        &::before {
            height: 1.875rem;
            left: 0.625rem;
            top: -0.275rem;
        }
    }

    &:hover {
        background-color: $blue;

        > div::after,
        div::before {
            background-color: $white;
        }
    }
}
