@import "~assets/styles/_variables.scss";

.toggleVisibility {
    cursor: pointer;
    font-size: 0.8125rem;
    line-height: 1.125rem;
    font-weight: 500;
    transition: color 0.3s ease-in-out;

    &:hover {
        color: $blue-dark;

        .eyeIcon, .eyeIconClosed {
            fill: $blue-dark;
        }
    }
}

@mixin shared-styles() {
    width: 1.25rem;
    height: auto;
    margin-right: 0.25rem;
    transition: fill 0.3s ease-in-out;
}

.eyeIcon {
    @include shared-styles();
    fill: $grey-dark;
}

.eyeIconClosed {
    @include shared-styles();
    fill: $grey-dark;
    transform: translateY(0.125rem);
}
