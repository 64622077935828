/* DayPicker styles */

.DayPicker {
    display: inline-block;
    font-size: 1rem;
}

.DayPicker-wrapper {
    position: relative;
    outline: none;
    flex-direction: row;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.DayPicker-Months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.DayPicker-Month {
    width: 100%;
    display: table;
    margin: 0 1em;
    margin-top: 1em;
    border-spacing: 0;
    border-collapse: collapse;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.DayPicker-NavBar {
}

.DayPicker-NavButton {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;
    display: inline-block;
    margin-top: 2px;
    width: 1.25em;
    height: 1.25em;
    mask-image: url('../images/icons/arrow-down.svg');
    -webkit-mask-image: url('../images/icons/arrow-down.svg');
    mask-position: center;
    mask-size: 70%;
    mask-repeat: no-repeat;
    background-color: $blue;
    transition: background-color 0.3s ease-in-out;
    outline: none;
    color: $blue;
    cursor: pointer;
}

.DayPicker-NavButton:hover {
    background-color: $blue-dark;
}

.DayPicker-NavButton--prev {
    margin-right: 1.5em;
    transform: rotate(90deg);
}

.DayPicker-NavButton--next {
    transform: rotate(-90deg);
}

.DayPicker-NavButton--interactionDisabled {
    display: none;
}

.DayPicker-Caption {
    display: table-caption;
    margin-bottom: 0.5em;
    padding: 0 0.5em;
    text-align: left;
}

.DayPicker-Caption > div {
    font-weight: 700;
    font-size: 0.875em;
}

.DayPicker-Weekdays {
    display: table-header-group;
    margin-top: 1em;
}

.DayPicker-WeekdaysRow {
    display: table-row;
}

.DayPicker-Weekday {
    display: table-cell;
    text-transform: uppercase;
    padding: 0.5em;
    color: $grey;
    text-align: center;
    font-size: 0.875em;
    font-weight: 700;
}

.DayPicker-Weekday abbr[title] {
    border-bottom: none;
    text-decoration: none;
}

.DayPicker-Body {
    display: table-row-group;
}

.DayPicker-Week {
    display: table-row;
}

.DayPicker-Day {
    display: table-cell;
    padding: 0.5em;
    border-radius: 50%;
    vertical-align: middle;
    text-align: center;
    color: $grey-dark;
    font-size: 0.75rem;
    font-weight: 700;
    cursor: pointer;
    outline: none;
    position: relative;
    z-index: 1;
    
    &::after {
        content: '';
        width: 1.875rem;
        height: 1.875rem;
        border: 1px solid transparent;
        transition: border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
        position: absolute;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
    }
}

.DayPicker-WeekNumber {
    display: table-cell;
    padding: 0.5em;
    min-width: 1em;
    border-right: 1px solid #EAECEC;
    color: #8B9898;
    vertical-align: middle;
    text-align: right;
    font-size: 0.75em;
    cursor: pointer;
}

.DayPicker--interactionDisabled .DayPicker-Day {
    cursor: default;
}

.DayPicker-Footer {
    padding-top: 0.5em;
}

.DayPicker-TodayButton {
    border: none;
    background-color: transparent;
    background-image: none;
    box-shadow: none;
    color: #4A90E2;
    font-size: 0.875em;
    cursor: pointer;
}

/* Default modifiers */

.DayPicker-Day--today {
    color: $blue;
    font-weight: 700;
}

.DayPicker-Day--outside {
    color: #8B9898;
    cursor: default;
}

.DayPicker-Day--disabled {
    color: #DCE0E0;
    cursor: default;
    /* background-color: #eff1f1; */
}

/* Example modifiers */

.DayPicker-Day--sunday {    
    background-color: #F7F8F8;
}

.DayPicker-Day--sunday:not(.DayPicker-Day--today) {
    color: #DCE0E0;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    position: relative;
    color: $blue;
    
    &::after {
        border-color:$blue;
    }
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
    
}

.DayPicker:not(.DayPicker--interactionDisabled)
.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
    background-color: transparent;
    color: $blue;

    &::after {
        background-color: $grey-bg;
    }
}

/* DayPickerInput */

.DayPickerInput {
    display: inline-block;
}

.DayPickerInput-OverlayWrapper {
    position: relative;
}

.DayPickerInput-Overlay {
    position: absolute;
    left: 0;
    z-index: 1;
    background: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}