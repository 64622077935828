@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@400;500;700;900&family=Overpass:wght@400;700&display=swap");

html {
    font-size: 16px;

    @media print {
        font-size: 12px;
    }
}

* {
    font-family: $primary-font !important;
}

body {
    font-family: $primary-font;
    font-size: 16px;
    font-weight: 400;
    color: $grey-dark;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

h1,
h2 {
    font-family: $primary-font;
    font-size: 1.375rem;
    line-height: 1.75rem;
    font-weight: 700;
}

h3 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 700;
}

h4 {
    font-size: 0.9375rem;
    line-height: 1.375rem;
    font-weight: 700;
}

a {
    text-decoration: none;
    font-size: inherit;
}

p {
    a,
    .link {
        color: $blue;
        position: relative;
        font-weight: 500;
        transition: color 0.3s ease-in-out;
        cursor: pointer;

        &::after {
            content: "";
            position: absolute;
            height: 0.0625rem;
            width: 100%;
            left: 0;
            bottom: 0.125rem;
            transition: background-color 0.3s ease-in-out;
        }

        &:hover {
            color: $blue-dark;

            &::after {
                background-color: $blue;
            }
        }
    }
}

::selection {
    background-color: $orange;
    color: $white;
}

ul {
    li {
    }
}
