.MuiTable-root {
    height: 100%;

    .MuiTableCell-root {
        font-family: $primary-font;
        border-color: $grey-lines;
        color: $grey-dark;
        padding: 1rem 1.25rem;
        height: 100%;

        .loadingDots {
            height: 1.3125rem;

            &.rightAlign {
                float: right;
            }
        }

        &--inner {
            height: 100%;
        }
    }

    .MuiTableHead-root {
        border-bottom: none;
    }

    .MuiTableCell-head {
        font-weight: 700;
        border-bottom: none;
        background-color: $grey-light;
        padding: 0;
        border-left: 1px solid darken($grey-lines, 2.5%);
        font-size: 0.75rem;
        letter-spacing: 0;

        &:first-child {
            border-radius: 0.5rem 0 0 0.5rem;
            border-left: none;
        }

        &:last-child {
            border-radius: 0 0.5rem 0.5rem 0;
        }

        &:first-child:last-child {
            border-radius: 0.5rem;
        }

        .label {
            padding: 0.875rem;
            display: block;
            height: 100%;
        }

        .MuiTableSortLabel-root {
            padding: 0.875rem 0.375rem 0.875rem 0.875rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            transition: color 0.2s ease;

            .MuiSvgIcon-root {
                width: 1rem;
                height: 1rem;
                fill: $blue;
                margin: 0;
            }

            &:hover {
                color: $blue-dark;

                .arrowSort {
                    opacity: 1;
                }
            }
        }

        &.MuiTableCell-alignRight .MuiTableSortLabel-root {
            padding: 0.875rem 0.875rem 0.875rem 0.375rem;
        }
    }
}
